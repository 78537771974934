.hra-survey-content {
  app-app-form-content {
    width: 100%;
  }

  app-app-form-actions {
    width: 100%;
  }

  .label {
    font-weight: 500;
    color: #3F51B5;
    margin-bottom: 8px;
  }

  .right {
    text-align: end;
  }
}
