html,
body {
  margin: 0;
  padding: 0;
  background-color: #f2f3f7;
  height: 100vh;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: inherit;
}

a.norm {
  text-decoration: underline;
}

// Overrides the `a` tags to show the default blue & underline of an href link
.blue-underline-urls {
  a {
    color: blue;
    text-decoration: underline;
  }
}

.description {
  color: #808080;
  font-weight: 400;
}

div.description {
  padding-bottom: 16px;
  font-size: 12px;
}

// Always hide the recaptcha badge, as it doesn't disappear after logging in, or
// if you were on any of the pages with it being loaded.
// See: https://developers.google.com/recaptcha/docs/faq
.grecaptcha-badge {
  visibility: hidden;
}

.full-summary {
  .qt0 {
    font-weight: 500;
    background-color: #ffffbf;
  }
}

button.clear {
  border: none;
  background: inherit;
  padding: 0;
}

mat-checkbox.text-wrap {
  label.mat-checkbox-layout {
    white-space: normal;
  }
}

.left-justify-button{
  display:flex !important;
  justify-content: start !important;
}

.search-button{
  margin:0!important;
}

.mat-mdc-form-field-hint{
  font-size:smaller;
}

// makes a normal mat-form-field look like a google search bar.
// Also removes the extra "hint" area under the bar.
mat-form-field.search {
  background-color: white;
  .mat-form-field-wrapper {
    padding-bottom: 0;
    margin: 0;
  }
  .mat-form-field-subscript-wrapper {
    margin: 0;
  }
}

mat-chip.no-opacity {
  &.mat-chip-disabled {
    opacity: 1 !important;
  }
}

// Hiding the OneTrust Cookie floating icon
#ot-sdk-btn-floating {
  display: none;
}

mat-form-field.no-label {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  .mat-form-field-flex {
    padding-top: 0;
  }
}

.clickable {
  cursor: pointer;
}

/** Used for fixing the scrolling on the main container **/
mat-drawer-container {
  .mat-drawer-content {
    // this is for the "normal-user"
    height: calc(100vh - 111px);

    // this is for the "admin-platform" routes, which use a smaller
    // top-level bar (its yellow)
    &.admin-platform-content {
      height: calc(100vh - 96px);
    }
    // this is for the "admin-company" routes, which don't use the
    // blue bar at the top, and thus are vastly larger
    // than the other setups, along with allowing the blue bar to
    // "stick" at the top of the page, hence overflow:hidden
    &.admin-company-content {
      height: calc(100vh - 50px);
      overflow: hidden;
    }
  }
  &.mobile {
    .mat-drawer-content {
      height: calc(100vh - 56px);
    }
    .mat-drawer {
      height: calc(100vh - 56px);
    }
  }
  &.tablet {
    .mat-drawer-content {
      height: calc(100vh - 56px);
    }
    .mat-drawer {
      height: calc(100vh - 56px);
    }
  }
}
