.dashboard-wrapper {
  height: calc(100vh - 174px);
  overflow: auto;
  padding: 32px 8px;
}

.dashboard-padding {
  padding: 32px 64px;
}

// used for any page that uses "widgets", which use mat-cards
// and a mix of our own styling
.widget-layout {
  .widget-header {
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid lightgrey;
    background-color: aliceblue;
  }

  .widget-title {
    font-size: 18px;
    margin-left: 32px;
  }

  .results-widget-title {
    font-size: 18px;
    padding: 16px;
  }

  .large-font {
    font-size: 16px;
    font-weight: 500;
  }

  .label {
    font-size: 14px;
    text-align: right;
    color: #5d5c62;
    font-weight: 500;
  }

  .value {
    overflow: hidden;
    word-break: normal;
  }

  .email {
    overflow: hidden;
    word-break: break-all;
  }

  .description {
    font-size: 14px;
  }
}

.schedule-button {
  &.mat-stroked-button {
    background-color: #ffff;
    color: #565fa5;
    border-radius: 100px;
    padding-right: 5px;
  }

  .mat-icon {
    background-color: #565fa5;
    color: #ffff;
    border-radius: 100px;
    font-size: 20px;
    height: 20px;
    width: 20px;
    line-height: 20px;
  }
}
