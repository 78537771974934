@use '@angular/material' as mat;
@import './font.scss';
$primary: mat.m2-define-palette(mat.$m2-indigo-palette, 900);

$accent: mat.m2-define-palette(mat.$m2-yellow-palette, 700);
$warn: mat.m2-define-palette(mat.$m2-red-palette, 500);
$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn
    ),
    typography: $custom-typography
  )
);

.color-primary {
  color: #243151;
}

.my-result-menu {
  background-color: #243151;
  width: 200px;

  &.mat-menu-panel {
    box-shadow: none;
    border-radius: 0;
  }

  a {
    color: white;
  }
  .mat-icon {
    color: white;
    line-height: 18px;
  }
}
