@use '@angular/material' as mat;
@use './scss/_mixins-theme.scss' as mixins;
@use "@material/banner/styles" as matBanner;
/* You can add global styles to this file, and also import other style files */
@import './scss/overrides.scss';
@import './scss/theme.scss';
@import './scss/layout.scss';
@import './scss/mat-overrides.scss';
@import './scss/font.scss';
@import './scss/user-reg.scss';
@import './scss/hra-survey.scss';
@import './scss/dialog.scss';
@import './scss/shared-dashboard.scss';
@import './scss/page-alert.scss';
@import './scss/label.scss';

@include mat.core();
@include mat.all-component-themes($theme);
@include mat.all-component-colors($theme);
@include mat.all-component-typographies($custom-typography);
@include mixins.theme($theme);

.loader-position {
  position: absolute;
  top: 50%;
  left: 50%;
}

.loader {
  border: 8px solid #f3f3f3;
  /* Light grey */
  border-top: 8px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.small-icon-button {
  width: 40px !important;
  height: 40px !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  & > *[role='img'] {
    width: 16px;
    height: 16px;
    font-size: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .mat-mdc-button-touch-target {
    width: 40px !important;
    height: 40px !important;
  }
}

.groupTypeOption {
  .mdc-label {
    width: 100%;
  }
  .mdc-form-field {
    color: #5d5c62;
    width: 100%;
  }
}

@media only screen and (max-width: 960px) {
  .mobile-mt {
    margin-top: 30px;
  }
  .mobile-hide {
    display: none !important;
  }
  .mobile-img {
    display: flex !important;
    align-self: center;
  }
}

// On smaller screens, set font size to 16px. Otherwise, Safari will zoom in on input fields.
@media only screen and (max-width: 500px) {
  .mobile-input {
    font-size: 16px !important;
  }

  .mobile-mb {
    margin-bottom: 75px;
  }
}

input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block;
}

.mat-mdc-card-avatar {
  margin-bottom: 0 !important;
}

.background-mat-primary {
  background-color: mat.get-theme-color($theme, primary, default) !important;
}

.text-semibold {
  font-weight: 500 !important;
}

.mat-error {
  color: #f44336;
}
