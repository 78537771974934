// Rounded version of the mat-button
button.mat-mdc-button-base.round {
  // Note sure if passing in a px value is ok here, but it works for anything but
  // huge size buttons
  border-radius: 100px;
}

// the slim version is used on the 2nd header, the blue bar, so it takes up less space
mat-toolbar.slim {
  $height: 46px;

  height: $height;
  min-height: $height;
  mat-toolbar-row {
    height: $height;
  }
}
.mat-menu-panel.no-min-height {
  min-height: unset;
}
.mat-menu-panel.no-min-width {
  min-width: unset;
}
.mat-menu-panel.no-max-width {
  max-width: unset;
}
// Change mat-errors to push content so long error messages don't overlap other fields
mat-form-field {
  &.ng-valid {
    .mat-form-field-wrapper {
      padding-bottom: 1.25em;
    }
  }

  &.ng-invalid,
  &.mat-form-field-invalid {
    .mat-form-field-wrapper {
      padding-bottom: 7px;
    }
  }

  &.ng-untouched {
    .mat-form-field-wrapper {
      padding-bottom: 1.25em;
    }
  }

  .mat-form-field {
    &-underline {
      position: static;
    }

    &-subscript-wrapper {
      position: static;
    }
  }
}

mat-expansion-panel.mat-expansion-panel.flat {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

mat-expansion-panel.mat-expansion-panel.no-border {
  box-shadow: none;
  border: none;
  padding: 0;

  .mat-expansion-panel-header {
    padding: 16px 8px;
  }

  .mat-expansion-panel-body {
    padding: 0 8px 8px;
  }
}

mat-card.flat {
  border: 1px solid rgba(0, 0, 0, 0.12);
  // background: #fafbfa;
  &:not([class*='mat-elevation-z']) {
    box-shadow: none;
  }
}

//making mat icon small and text small as well
mat-icon.icon-small {
  font-size: 20px;
  height: 20px;
  width: 20px;
  line-height: 20px;
}

mat-icon.icon-large {
  font-size: 55px;
  height: 55px;
  width: 55px;
  line-height: 55px;
}

mat-card-header.empower-report {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 150px;
  background-color: #71ac29;
  font-weight: 500;
  color: #ffff;
  font-size: 18px;
}

// Fixes issues where the text for a mat-checkbox doesn't wrap
mat-checkbox.text-wrap {
  .mat-checkbox-layout {
    white-space: normal;
  }
}

// removes the 180px min-width for mat-form-fields
mat-form-field.no-min-width {
  .mat-form-field-infix {
    width: inherit;
  }
}

//Centers the mat spinner
mat-spinner.circle-center {
  margin: 0 auto;
}

.mat-mdc-progress-bar.custom-color {
  --mdc-linear-progress-active-indicator-color: currentColor;
  .mdc-linear-progress__buffer-bar {
    background-color: #eee;
  }
}

.mat-mdc-progress-spinner.custom-color {
  circle {
    stroke: currentColor;
  }
}

.mat-mdc-progress-spinner.full-spinner {
  circle {
    stroke: rgba(0, 0, 0, 0.1);
  }
}

.mdc-button {
  line-height: 100% !important;
  text-align: center;
}

@media screen and (max-width: 400px) {
  .mdc-button {
    padding: 0;
  }
}

@media only screen and (max-width: 600px) {
  mat-snack-bar-container.mdc-snackbar {
    margin-bottom: 5vh;
  }

  mat-form-field {
    .mat-mdc-form-field {
      &-error-wrapper {
        position: relative !important;
        padding: 0px 0px;
      }
      &-hint-wrapper {
        position: relative;
      }
    }

    .mat-mdc-text-field-wrapper {
      flex: none;
      height: 55px;
    }

    .mat-mdc-form-field-subscript-wrapper {
      line-height: 15px;
    }

    .mat-mdc-form-field-bottom-align::before {
      height: 5px;
    }
  }
}

mat-expansion-panel.event-location-panel {
  .mat-expansion-panel-body {
    padding: 8px 16px 16px;
  }

  &.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover {
    background: #ebefff;
  }
}

// Removing, this was set **2 years** ago, and I'm unsure where it was required
// or if there is impact, but its impacting #3297 empty values, and why
// this was done isn't documented.
// .mat-select-panel mat-option.mat-option {
//   height: unset;
// }

.mat-option-text.mat-option-text {
  white-space: normal;
  line-height: 25px;
  padding: 8px 0;
}

mat-list-item.list-additional-service {
  height: auto;
  font-size: 14px;
  color: #5d5c62;
  padding: 0;
}

mat-card.optional-test,
mat-mdc-card.optional-test {
  background: #fafafa;
  cursor: pointer;

  &:hover {
    background: #ebefff;
  }
}

.hra-container {
  .mat-tab-header {
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;
  }

  .mat-tab-body-content {
    background: white;
  }

  .mat-tab-label {
    height: 70px;
    min-width: 0;
  }

  .mat-tab-label-active {
    background: #bac8ff;
  }

  .mat-tab-labels {
    background: #f0f3f8;
  }

  .mat-radio-label {
    white-space: normal;
  }
}
.mat-option.compact-option {
  font-size: 12px;
  line-height: 40px;
  height: auto;
  .mat-option-text {
    padding: 4px 0;
    line-height: 20px;
  }
}

.mat-form-field.white-bg {
  .mat-form-field-outline {
    background-color: white !important;
  }
}

mat-error {
  margin: 8px 0;
}
