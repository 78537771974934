@use '@angular/material' as mat;
@import './theme.scss';
@mixin typography($theme) {
  .mat-title {
    // Get the headline font from the theme.
    font: mat.get-theme-typography($theme, headline-1);
  }

  .mat-headline {
    font: mat.get-theme-typography($theme, headline-1);
    font-weight: bold;
    margin: 0 0 16px;
    letter-spacing: normal;
  }

  .mat-subheading-2 {
    font: mat.get-theme-typography($theme, subtitle-2);
    margin: 0 0 16px;
  }

  .mat-display-1 {
    font: mat.get-theme-typography($theme, headline-1);
    font-size: 34px;
    font-weight: 400;
    line-height: 40px;
    margin: 0 0 64px;
  }

  .mdc-floating-label {
    font: mat.get-theme-typography($theme, body-2);
    font-size: 15px !important;
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
