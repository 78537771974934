@use '@angular/material' as mat;
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/poppins-regular-webfont.woff') format('woff');
}

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.m2-define-typography-config(
  $font-family: 'Poppins, Roboto',
  $headline-1:
    mat.m2-define-typography-level(20px, 32px, 500, $letter-spacing: 'normal'),
  $headline-5:
    mat.m2-define-typography-level(24px, 48px, 700, $letter-spacing: 'normal'),
  $subtitle-2:
    mat.m2-define-typography-level(16px, 28px, 400, $letter-spacing: 'normal'),
  $body-1:
    mat.m2-define-typography-level(14px, 24px, 400, $letter-spacing: 'normal'),
  $body-2:
    mat.m2-define-typography-level(14px, 24px, 400, $letter-spacing: 'normal'),
  $button:
    mat.m2-define-typography-level(14px, 24px, 500, $letter-spacing: 'normal')
);
