.label {
  font-size: 14px;
  color: #5d5c62;
  font-weight: 500;
}

.single-line-label {
  font-size: 14px;
  color: #5d5c62;
  font-weight: 500;

  // force spacing of label, might not work for larger labels
  width: 120px;
  max-width: 120px;
}

.left-label {
  font-size: 14px;
  color: #5d5c62;
  font-weight: 500;
  text-align: left;
}

.title-heading {
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #bbb;
  margin-bottom: 16px;
  padding-bottom: 8px;
}
