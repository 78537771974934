.full-height {
  height: 100%;
  min-height: 100%;
}

.full-width {
  width: 100%;
  min-width: 100%;
}

.margin {
  margin: 16px;
}

.padding {
  padding: 16px;
}

.ehs-logo {
  margin: 8px;
  height: auto;
  width: 200px;
}

// The "external" pages accessible outside of main all
// require different padding to support scrolling
.center-page-container {
  height: 100%;
  overflow: auto;
}

// This class can be used to align an element with a material field
.align-with-mat-field {
  margin-bottom: 14px;
}

.align-with-mat-field-with-hint {
  margin-bottom: 38px;
}

.align-with-mat-field-with-hint-start {
  margin-top: 4px;
}

// class for multiple "login" pages with a single card
.center-container {
  width: calc(100% - 128px);
  max-width: 800px;
  padding: 32px 64px;
}

mat-table.lookup-table {
  .mat-header-row,
  .mat-option,
  .mat-row {
    min-height: 35px;
    height: 35px;
    line-height: 35px;
  }

  .mat-option.mat-active {
    background: inherit;
  }

  .mat-header-row {
    border-bottom-color: transparent;
  }
}

// simple "inner-card" organizational card.
// only provides the outline
.card-outline {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
